import {useEffect, useState } from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid, Typography,useTheme,Box,Container,ButtonBase,Button,makeStyles,Tooltip,IconButton} from '@material-ui/core';
import parse from 'html-react-parser';
 import { ContactForm } from './ContactFormDailog';
  
import { Helmet  } from 'react-helmet-async';
import {Link, useParams} from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';

// Copy Start
import {useContext} from 'react';
import {NewLoginForm} from './NewLoginDailogForm';
import { BackDropLoad } from './LoadingBackDrop';
import { UserContext } from './userContext';
// Copy End
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
 // RedditShareButton,
 // RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
 // ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  RedditShareButton,
  RedditIcon, 
  
} from "react-share";

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}


const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const SingleCareer=(props)=>{
  const url=window.location.href;
    const theme = useTheme();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState();
    //  const id =props?.id;
  
const id=useParams()

 
    const [showContactForm,setShowContactForm]=useState(false);
 
    // Copy Start
    const {user, setUser} = useContext(UserContext);
    const [showLoginForm,setShowLoginForm]=useState( user?false:true);
    const [wait,setWait]=useState(false);

    const contentStyle = user? {}: { filter: "blur(4px)", pointerEvents: "none" }; 

    // Copy End

    const closeContactForm=()=>{
        setShowContactForm(false);
    }


    const closeLoginForm=()=>{
        setShowLoginForm(false);
    }


    const showbackgroundLoad=()=>{
        setWait(true);
    }

    const hidebackgroundLoad=()=>{
        setWait(false);
    }

    const openContactForm=()=>{
        
        setShowContactForm(true);
    }

    useEffect(()=>{
      window.scrollTo(0, 0);
        Axios.post("/new_apis/get-single-career",{id:id?.id},{ 
          headers: { key: key },
         }).then((res) => {
            setData(res?.data?.data[0]);
               
            setLoading(false);
         }); 
                   
      },[]);

      const handleContextMenu = (e) => {
        e.preventDefault(); // Disable right-click
      };

      const [tooltipText, setTooltipText] = useState("Click to copy the URL");

      const handleCopy = () => {
        // Copy the URL to clipboard
        navigator.clipboard.writeText(window.location.href)
          .then(() => {
            setTooltipText("Link copied to clipboard!"); // Update tooltip text
            setTimeout(() => setTooltipText("Click to copy the URL"), 2000); // Reset tooltip text after 2 seconds
          })
          .catch((err) => console.error("Failed to copy: ", err));
      };

    return (<div>
            
         <Container maxWidth="md">
        
            <br/><br/>
            <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
          Careers
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className="text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Job Openings:</span> Discover the Latest Opportunities in Public and Private Sectors.
      </Typography>
      </Container>
      <br/><br/>
      {loading?<Loading/>: <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px" }}>  
      <Helmet>
                    <title> {data?.job_title} - Careers | ESAY - Engineering Sources Around You</title>
                     
                </Helmet>
                <div style={contentStyle}>
      <Grid
        container
        direction="row"
            justifyContent="space-between"
            alignItems="center"
        spacing={2}

        style={{ userSelect: "none", // CSS to disable text selection
          MozUserSelect: "none", // For Firefox
          WebkitUserSelect: "none", // For Safari
          msUserSelect: "none", // For older IE
          }}
          onContextMenu={handleContextMenu}
          onCopy={handleCopy}
        >
         
          {/* Copy start */}
<BackDropLoad wait={wait}/>
                    <NewLoginForm open={showLoginForm} showload={()=> setWait(true) } hideload={()=>setWait(false)} closeform={()=>setShowLoginForm(false)} openloginform={()=>{setShowLoginForm(true)}} />
                    {/* Copy End */}
            <Grid item xs={12}>
            <Typography variant="h5" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 {data?.job_title}
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b style={{color:theme.palette.primary.main}}>Share Options:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        <Tooltip title={tooltipText} arrow  style={{color:'#ffffff'}}>
                          <IconButton onClick={handleCopy}>
                            <LinkIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                        </Typography>
                        
                     </Grid>
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Posted By:</b> {data?.posted_by} - {data?.job_location}
              </Typography>
            </Grid>
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Posted Date:</b> {data?.posted_date}  
              </Typography>
            </Grid>
             
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Location:</b> {data?.job_address}
            </Typography>
            </Grid>
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Type:</b> {data?.job_type}
            </Typography>
            </Grid>
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>No. of Openings:</b> {data?.current_openings}
            </Typography>
            </Grid>
            <Grid item >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Required Qualification:</b> {data?.job_qualification}
            </Typography>
            </Grid>
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Start Date for Applying:</b> {data?.start_date}
            </Typography>
            </Grid>
            <Grid item  >
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Last Date for Applying:</b> {data?.last_date}
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="body2" className="text-left" gutterBottom >
              <b style={{color:theme.palette.primary.main}}>Details:</b>  
            </Typography>
            {parse(data?.job_desc)}
        
            </Grid>
            
            <Grid item xs={12} className="text-center">
            <BackDropLoad wait={wait}/>
              </Grid>
                     {user && (<ContactForm open={showContactForm} showload={showbackgroundLoad} hideload={hidebackgroundLoad} closeform={closeContactForm} data={data} url={"careers"} />)}
              <Grid item xs={12} className="text-center">
                {data?.type==='1'?
            <Button variant="contained"  style={{width:"50%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
              window.open(data?.job_url, '_blank', 'noopener,noreferrer');
               
          }}  >View Details</Button>
            :<Button variant="contained"  style={{width:"50%"}} fullWidth={false} color="primary"  className="linkstyle"  onClick={()=>{
              if(user){
                  setShowContactForm(true);
                   
               }else{
                   setShowLoginForm(true);
               }
          }} >Apply Now</Button>
             }
              </Grid>
 
 </Grid>
 </div>
 </Box>}
<br/><br/><br/>
</Container>
    </div>);
}