import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid, Typography,useTheme,Card,CardContent,ButtonBase,CardMedia,makeStyles, Container,Box, Hidden,  useMediaQuery, Tooltip,IconButton} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link, useParams} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';
import Lightbox from "yet-another-react-lightbox";
import LinkIcon from '@material-ui/icons/Link';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
 // RedditShareButton,
 // RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
 // ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  RedditShareButton,
  RedditIcon, 
  
  
} from "react-share";
import "yet-another-react-lightbox/styles.css";
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}


const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 6px 20px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const SingleAwardAndNew=()=>{
    const theme = useTheme();
    const url=window.location.href;

    let {slug, id} = useParams();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [data, setData] = useState([]);
    const [metadata,setMetadata] = useState();

    const [open, setOpen] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [clickedIndex, setClickedIndex] = useState(null);
    const [open1, setOpen1] = useState(false);
    const matches = useMediaQuery("(min-width:1100px)");
    const [tooSlideImages, setTooSlideImages] = useState([]);
    const [slideImages, setSlideImages] = useState();
    const [final, setFinal] = useState([]);
    const [closeOnPullDown, setCloseOnPullDown] =  useState(true);
    const [closeOnBackdropClick, setCloseOnBackdropClick] = useState(true);
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoading(true);

       const formData={};
       formData.id=id;

        Axios.post("/new_apis/get-single-article",formData,{ 
          headers: { key: key },
         }).then((res) => {
            setData(res.data.article_data);
            setMetadata(res.data.seo_data[0])
            setLoading(false);
         }); 
                   
      },[id]);

// Function to handle click on any image (other than the first) and open a lightbox with a specific starting index.
const handleImageClick = (index) => {
  var slideImages = data.article_images.split(",");
  // Rearrange the images array to start from the clicked index and set it for the lightbox.
  var shiftImages = slideImages.splice(0, index + 1);
  slideImages.push(...shiftImages);
  // Set the images for the lightbox and open it.
  setTooSlideImages(slideImages);
  setOpen1(true);
   
};

      const createSlug = (title) => {
        return title
            .toLowerCase() // Convert to lowercase
            .replace(/[^\w ]+/g, '') // Remove all non-word chars
            .replace(/ +/g, '-'); // Replace spaces with hyphens
    };

    const handleContextMenu = (e) => {
      e.preventDefault(); // Disable right-click
    };
  
    const [tooltipText, setTooltipText] = useState("Click to copy the URL");

  const handleCopy = () => {
    // Copy the URL to clipboard
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setTooltipText("Link copied to clipboard!"); // Update tooltip text
        setTimeout(() => setTooltipText("Click to copy the URL"), 2000); // Reset tooltip text after 2 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

    return (<div>
                {(metadata && data)  && <Helmet>
        <title>  {metadata.seo_title}</title>
            <meta name="description" content={metadata.seo_desc} />
            <meta name="keywords" content={metadata.seo_keywords}></meta>
        </Helmet>}
        {loading?<Loading/>:
        <div className="container" style={{ userSelect: "none", // CSS to disable text selection
          MozUserSelect: "none", // For Firefox
          WebkitUserSelect: "none", // For Safari
          msUserSelect: "none", // For older IE
          }}
          onContextMenu={handleContextMenu}
          onCopy={handleCopy}>
            <br/><br/>
            {open1 && (
                      // Lightbox component for additional images in a separate state (`tooSlideImages`).
                      <Lightbox
                        open={open1}
                        close={() => setOpen1(false)}
                        slides={tooSlideImages.map((src, index) => ({
                          src: `/articles/${src}`,
                          index,
                        }))}

                        controller={{ closeOnPullDown, closeOnBackdropClick }}
                        render={{
                            buttonPrev: tooSlideImages.length>1 ? undefined : () => null,
                            buttonNext: tooSlideImages.length>1 ? undefined : () => null,
                          }}
                       
                      />
                    )}
            <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
                Honors & Highlights 
            </Typography>
            <Container maxWidth="sm">
                <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
                    <span style={{color:theme.palette.primary.main }}>Awards and Media Coverage:</span> 
                    Celebrating Achievements and Industry Recognition. 
                </Typography>
            </Container>
            <br/><br/> 
            <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                    >
                     <Grid item xs={12}>
                     <h3 style={{color:"#ffd24d",fontWeight: 'normal',letterSpacing: '3px'}}>{data.article_title}</h3>
                    </Grid>  
             

              
                    <Grid item xs={12}>
                    <p style={{fontWeight: 'normal',letterSpacing: '3px',textTransform: 'uppercase',textAlign:'right'}}><b style={{color:'#ffd24d'}}>Posted Date: </b> {data.article_published_date}</p>
                    </Grid>   
                    {data.article_images.split(",").length===1?(
                        <Grid item xs={12}>
                          <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "600px",  
                                 position: "relative",
                              }}
                            >
                            <img 
                                src={`/articles/${data.article_images.split(",")[0]
                            }`}
                            onClick={() => {
                              handleImageClick(-1);
                              
                            }}
                                alt={data.article_title}
                                style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                            />
                        </Box>
                        </Grid>
                        ):(
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                          >
                           <Grid item xs={12} sm={12} md={6}> 
                           <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "600px",  
                                 position: "relative",
                                 
                              }}
                            >
                                      <img 
                                          src={`/articles/${data.article_images.split(",")[0]
                                      }`}
                                      onClick={() => {
                                        handleImageClick(-1);
                                        
                                      }}
                                          alt={data.article_title}
                                          style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                                      />
                            </Box>        
                           </Grid>
                           <Hidden smDown>
                            <Grid item  md={6}  > 
                            {  data?.article_images.split(",").length >3 ? <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  data?.article_images.split(",")
                              .slice(1, 5)
                              .map((i,  index) => (
                                <>
                               { index!== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "300px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/articles/${i}`}
                                      onClick={() => {
                                        
                                        handleImageClick(index);
                                        
                                      }}
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid>}
                                
                                { index=== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "300px",  
                                       position: "relative",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       handleImageClick(index);
                                      
                                    }}
                                  >
                                    
                                    <img
                                      src={`/articles/${i}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        
                                        opacity:data.article_images.split(",").length > 5 ?"0.5":"1.0"   
                                      }}
                                    />
                                   {data?.article_images.split(",").length > 5 && <Typography
                                    variant="h2"
                                    style={{
                                      color: "#ffd24d",
                                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                                      position: 'absolute', 
                                      top: '50%',           
                                      left: '50%',          
                                      transform: 'translate(-50%, -50%)',  
                                    }}
                                  >
                                    +
                                    {data?.article_images.split(",").length - 5}
                                  </Typography> }
                                </Box>
                               
                                </Grid>}
                                
                                </>
                              ))}

                              
                            </Grid>:<Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  data?.article_images.split(",")
                              .slice(1, 3)
                              .map((i,  index) => (
                                <>
                               <Grid item   md={12}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "300px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/articles/${i}`}
                                      onClick={() => {
                                         
                                        handleImageClick(index);
                                        
                                      }}
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid> 
                                 
                                
                                </>
                              ))}

                              
                            </Grid>}
                            
                            </Grid>
                            </Hidden>             
                          </Grid>
                        </Grid>
                        )}  
                        <Hidden mdUp>
                        <Grid item xs={12}>
                        <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                { data?.article_images.split(",")
                              .slice(1, 5)
                              .map((i,  index) => (
                                <>
                               { index!== 3 && <Grid item  xs={3} sm={3}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "100px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/articles/${i}`}
                                      onClick={() => {
                                         
                                        handleImageClick(index);
                                        
                                      }}
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid>}
                                
                                { index=== 3 && <Grid item   xs={3} sm={3}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "100px",  
                                       position: "relative",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       handleImageClick(index);
                                      
                                    }}
                                  >
                                    
                                    <img
                                      src={`/articles/${i}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        
                                        opacity:data.article_images.split(",").length > 5 ?"0.5":"1.0"   
                                      }}
                                    />
                                   {data?.article_images.split(",").length > 5 && <Typography
                                    variant="h2"
                                    style={{
                                      color: "#ffd24d",
                                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                                      position: 'absolute', 
                                      top: '50%',           
                                      left: '50%',          
                                      transform: 'translate(-50%, -50%)',  
                                    }}
                                  >
                                    +
                                    {data?.article_images.split(",").length - 5}
                                  </Typography> }
                                </Box>
                               
                                </Grid>}
                                
                                </>
                              ))}

                              
                            </Grid>
                        </Grid>
                        </Hidden>
                        <Grid item xs={12} ></Grid>
                    <Grid item xs={12} style={{textAlign:'left'}}>
                        <p  style={{fontWeight: 'normal',letterSpacing: '3px',textTransform: 'uppercase',color:'#ffd24d'   }}>
                                      <b>Share this Article:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        <Tooltip title={tooltipText} arrow style={{color:'#ffffff'}}>
                          <IconButton onClick={handleCopy} >
                            <LinkIcon fontSize="medium"  />
                          </IconButton>
                        </Tooltip>
                        </p>
                        
                     </Grid>
                    <Grid item xs={12}>
                        <p className="article_desc">{parse(
                                   data.article_desc
                                       

                        )}</p>
                        </Grid>
            </Grid> <br/><br/><br/>
        </div>}
    </div>);
}