import { useEffect, useState } from "react";
import Axios from "axios";
import { ConvArea } from "./ConvArea";
import { ConvTemp } from "./ConvTemp";
import { ConvDischarge } from "./ConvDischarge";
import { ConvLength } from "./ConvLength";
import { ConvMoment } from "./ConvMoment";
import { ConvPower } from "./ConvPower";
import { ConvPressure } from "./ConvPressure";
import { ConvStress } from "./ConvStress";
import { ConvVelocity } from "./ConvVelocity";
import { ConvVolume } from "./ConvVolume";
import { ConvWeight } from "./ConvWeight";
import { ConvWtper } from "./ConvWtper";
import { Grid, Typography, useTheme, Container, CardContent, ButtonBase, Button, makeStyles } from '@material-ui/core';
import parse from 'html-react-parser';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

 // Copy Start
 import {useContext} from 'react';
 import {NewLoginForm} from './NewLoginDailogForm';
 import { BackDropLoad } from './LoadingBackDrop';
 import { UserContext } from './userContext';
 // Copy End
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const useStyles = makeStyles({
  nounderline: {
    "&:hover": { textDecoration: "none" },
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
  },
});
export const ConversionsList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [metadata, setMetadata] = useState();


  const location = useLocation();
 // Copy Start
 const {user, setUser} = useContext(UserContext);
 const [showLoginForm,setShowLoginForm]=useState( user?false:true);
 const [wait,setWait]=useState(false);

 const contentStyle = user? {}: { filter: "blur(4px)", pointerEvents: "none" }; 

 // Copy End
  useEffect(() => {
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", { 'type': "conversions" }, {
      headers: { key: key },
    }).then((res) => {

      if (res.data.status) {

        setMetadata(res.data.metadata[0])
      } else {
        setMetadata({
          'seo_title': "Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc': "Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords": "best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"
        });
      }

    });
  }, []);

  return (<div>
    {metadata && <Helmet>
      <title>{metadata.seo_title}</title>
      <meta name="description" content={metadata.seo_desc} />
      <meta name="keywords" content={metadata.seo_keywords}></meta>
    </Helmet>}
    <Container  >
      <br /><br />
      <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
           Conversions 
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Quickly Convert On the Go:</span> Your Essential Offline Tool for Field Calculations.
      </Typography>
      </Container>
      <br /><br />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
{/* Copy start */}
<BackDropLoad wait={wait}/>
                    <NewLoginForm open={showLoginForm} showload={()=> setWait(true) } hideload={()=>setWait(false)} closeform={()=>setShowLoginForm(false)} openloginform={()=>{setShowLoginForm(true)}} />
                    {/* Copy End */}
        <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/length"} className="linkstyle" style={(location.pathname==='/conversions-list/length')?{backgroundColor:'white', border:"1px solid black"}:{}}>Length</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/area"} className="linkstyle" style={(location.pathname==='/conversions-list/area')?{backgroundColor:'white', border:"1px solid black"}:{}}>Area</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/volume"} className="linkstyle" style={(location.pathname==='/conversions-list/volume')?{backgroundColor:'white', border:"1px solid black"}:{}}>Volume</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/weight"} className="linkstyle" style={(location.pathname==='/conversions-list/weight')?{backgroundColor:'white', border:"1px solid black"}:{}}>Weight</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/wtunitl"} className="linkstyle" style={(location.pathname==='/conversions-list/wtunitl')?{backgroundColor:'white', border:"1px solid black"}:{}}>Wt / unit L</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/stress"} className="linkstyle" style={(location.pathname==='/conversions-list/stress')?{backgroundColor:'white', border:"1px solid black"}:{}}>Stress</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/velocity"} className="linkstyle" style={(location.pathname==='/conversions-list/velocity')?{backgroundColor:'white', border:"1px solid black"}:{}}>Velocity</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/discharge"} className="linkstyle" style={(location.pathname==='/conversions-list/discharge')?{backgroundColor:'white', border:"1px solid black"}:{}}>Discharge</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/moment"} className="linkstyle" style={(location.pathname==='/conversions-list/moment')?{backgroundColor:'white', border:"1px solid black"}:{}}>Moment</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/power"} className="linkstyle" style={(location.pathname==='/conversions-list/power')?{backgroundColor:'white', border:"1px solid black"}:{}}>power</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/pressure"} className="linkstyle" style={(location.pathname==='/conversions-list/pressure')?{backgroundColor:'white', border:"1px solid black"}:{}}>Pressure</Button>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/temp"} className="linkstyle" style={(location.pathname==='/conversions-list/temp')?{backgroundColor:'white', border:"1px solid black"}:{}}>Temperature</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={10} lg={10} md={9} sm={8} xs={12} style={contentStyle}>
          <Routes>
          <Route path={"wtunitl"}  element={<><Helmet><title>Weight per Unit Length - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvWtper /></>} />
          <Route path={"weight"}  element={<><Helmet><title>WEIGHT - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvWeight /></>} />
          <Route path={"volume"}  element={<><Helmet><title>Volume - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvVolume /></>} />
          <Route path={"velocity"}  element={<><Helmet><title>Velocity - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvVelocity /></>} />
          <Route path={"stress"}  element={<><Helmet><title>Stress - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvStress /></>} />
          <Route path={"pressure"}  element={<><Helmet><title>Pressure - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvPressure /></>} />
          <Route path={"power"}  element={<><Helmet><title>Power - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvPower /></>} />
          <Route path={"moment"}  element={<><Helmet><title>Moment - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvMoment /></>} />
          <Route path={"length"}  element={<><Helmet><title>Length - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvLength /></>} />
          <Route path={"area"}  element={<><Helmet><title>Area - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvArea /></>} />
          <Route path={"temp"}  element={<><Helmet><title>Temperature - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvTemp /></>} />
          <Route path={"discharge"}  element={<><Helmet><title>Discharge - Conversions | ESAY - Engineering Sources Around You</title></Helmet><ConvDischarge /></>} />
          </Routes>
        </Grid>
      </Grid>
      <br /><br /><br />
    </Container>
  </div>);
}