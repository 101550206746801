import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid,Box,Typography,useTheme,Button,Tooltip,IconButton} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link,useParams,useNavigate} from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Helmet  } from 'react-helmet-async';
import LinkIcon from '@material-ui/icons/Link';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
   // RedditShareButton,
   // RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
   // ViberShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    XIcon,
    RedditShareButton,
    RedditIcon, 
    
  } from "react-share";


  // Copy Start
import {useContext} from 'react';
import {NewLoginForm} from './NewLoginDailogForm';
import { BackDropLoad } from './LoadingBackDrop';
import { UserContext } from './userContext';
// Copy End
 const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
 

export const SingleInfo=(props)=>{
    const url=window.location.href;
    const theme = useTheme();
    let {id} = useParams();
    const [loading,setLoading]=useState(true);
    
    const [infoData,setInfoData]=useState();
    const [infoChilds,setInfoChilds]=useState();
    const [parent,setParents]=useState();

    // Copy Start
    const {user, setUser} = useContext(UserContext);
    const [showLoginForm,setShowLoginForm]=useState( user?false:true);
    const [wait,setWait]=useState(false);

    const contentStyle = user? {}: { filter: "blur(4px)", pointerEvents: "none" }; 

    // Copy End

    useEffect(()=>{
        window.scrollTo(0, 0);
        setLoading(true);
         
        props.openlist(id);
        Axios.post("/new_apis/get-enginfo-singleinfo", {id:id}, {  
           
          headers: { key: key },
         }).then((res) => {
            
            if(res?.data?.status){
                setInfoData(res?.data?.desc[0]);
                setInfoChilds(res?.data?.childs);
                setParents(res?.data?.parentDetails[0]);
                
                 setLoading(false);
            }else{

            }
             
            
         }); 
                   
      },[id]);


      

      const handleContextMenu = (e) => {
        e.preventDefault(); // Disable right-click
      };

      const [tooltipText, setTooltipText] = useState("Click to copy the URL");

      const handleCopy = () => {
        // Copy the URL to clipboard
        navigator.clipboard.writeText(window.location.href)
          .then(() => {
            setTooltipText("Link copied to clipboard!"); // Update tooltip text
            setTimeout(() => setTooltipText("Click to copy the URL"), 2000); // Reset tooltip text after 2 seconds
          })
          .catch((err) => console.error("Failed to copy: ", err));
      };
      return (<div>{loading?<Loading />:(<div className="container" >
           
           {infoData?.info_child_cat_ids && <Helmet>
        <title>{infoData?.info_child_cat_ids} | EngInfo | ESAY - Engineering Sources Around You</title>
          
          
        </Helmet>} 
           
           <Box boxShadow={3} p={3}  style={{backgroundColor:"white",border:"2px groove #ffd24d",borderRadius:"10px"}}> 
           <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                style={contentStyle}
                >

                    {/* Copy start */}
                    <BackDropLoad wait={wait}/>
                    <NewLoginForm open={showLoginForm} showload={()=> setWait(true) } hideload={()=>setWait(false)} closeform={()=>setShowLoginForm(false)} openloginform={()=>{setShowLoginForm(true)}} />
                    {/* Copy End */}
                <Grid item xs={12}>
                    {infoData && (
                        <Typography variant="h5" className="text-center" style={{color:theme.palette.secondary.main ,width:"100%"}} gutterBottom>
                        {infoData?.info_child_cat_ids}  
                        </Typography>)}
                </Grid>
                {infoData && (<Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={2}
                        >
                        <Grid item >
                            {parent ? (<Link to={"/eng-info/info-detail/"+parent?.parent_slug+"/"+parent?.parent_id}   className="anchorTag" style={{ textDecoration:"underline" }}> <ArrowBackIcon/> Go Back to <b>{parent?.parent_name}</b> </Link>):
                            (<Link to={"/eng-info/home"}   className="anchorTag" style={{ textDecoration:"underline"  }}> <ArrowBackIcon/> Go Back to <b>EngInfo</b> Home</Link>)
                        }
                        
                        </Grid>
                        <Grid item >
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Share Options:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>   
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        <Tooltip title={tooltipText} arrow>
                          <IconButton onClick={handleCopy}>
                            <LinkIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                        </Typography>
                        
                     </Grid>
                        </Grid>
                    </Grid>        
                </Grid>)  }  
                <Grid item xs={12}>
                    {infoData && infoData.info_data_desc!=='' && (
                        <div style={{color:"black",userSelect: "none", // CSS to disable text selection
                            MozUserSelect: "none", // For Firefox
                            WebkitUserSelect: "none", // For Safari
                            msUserSelect: "none", // For older IE
                            }}
                            onContextMenu={handleContextMenu}
                            onCopy={handleCopy}
                            >
                        {parse(infoData.info_data_desc)} 
                        </div>
                    )}
                </Grid>  
                <Grid item xs={12}>
                {infoChilds && (
                         <Grid
                         container
                         direction="row"
                         justify="flex-start"
                         alignItems="center"
                         spacing={3}
                       >
                           {infoChilds?.map((item ) => (
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="text-center">
                            <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/eng-info/info-detail/"+item?.info_data_slug+"/"+item?.info_data_id} className="linkstyle">
                                {item?.info_child_cat_ids}
                            </Button>
                        </Grid>))}
                       </Grid>
                     )}
                </Grid>  
            </Grid>
                     
                 
                     
                    
                   
                        
                     
                    
                    </Box>
           
      </div>)} </div>);

     
}