import {useEffect, useState  } from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid,Box,Typography,useTheme,Button,Tooltip,IconButton} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link,useParams,Route} from 'react-router-dom';

import { useNavigate} from "react-router-dom";
import { Helmet  } from 'react-helmet-async';

import LinkIcon from '@material-ui/icons/Link';


import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
   // RedditShareButton,
   // RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
   // ViberShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    XIcon,
    RedditShareButton,
    RedditIcon, 
    
  } from "react-share";

//   import { LoginForm } from './NewLoginDailogForm';

  // Copy Start
  import {useContext} from 'react';
  import {NewLoginForm} from './NewLoginDailogForm';
  import { BackDropLoad } from './LoadingBackDrop';
  import { UserContext } from './userContext';
  // Copy End

 const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const SingleTrend=(props)=>{
    const url=window.location.href;
    const history=useNavigate();
    const theme = useTheme();
    let {id} = useParams();
    
    const [loading,setLoading]=useState(true);
    
    
    const [trendData,setTrendData]=useState();

    // Copy Start
    const {user, setUser} = useContext(UserContext);
    const [showLoginForm,setShowLoginForm]=useState( user?false:true);
    const [wait,setWait]=useState(false);
    // Copy End

    useEffect(()=>{
         
        window.scrollTo(0, 0);
        setLoading(true);
        Axios.get("/new_apis/get-single-trend",{ params:{
            trendid:id
          },
          headers: { key: key },
         }).then((res) => {
            
            setTrendData(res.data.trenddata);
             
            setLoading(false);
            
            
         }); 
                   
      },[id]);


      const handleContextMenu = (e) => {
        e.preventDefault(); // Disable right-click
      };

      const [tooltipText, setTooltipText] = useState("Click to copy the URL");

       

  const handleCopy = () => {
    // Copy the URL to clipboard
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setTooltipText("Link copied to clipboard!"); // Update tooltip text
        setTimeout(() => setTooltipText("Click to copy the URL"), 2000); // Reset tooltip text after 2 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

      return (<div> {loading?<Loading />:(<div >
           
           <Helmet>
          <title>{trendData.blog_title} | Trends | ESAY - Engineering Sources Around You</title>
           
      </Helmet> 
             
                    {trendData && (
                    <Box boxShadow={3} p={3}  style={{backgroundColor:"white",border:"2px groove #ffd24d",borderRadius:"10px"}}> 
                    {/* Copy start */}
                    <BackDropLoad wait={wait}/>
                    <NewLoginForm open={showLoginForm} showload={()=> setWait(true) } hideload={()=>setWait(false)} closeform={()=>setShowLoginForm(false)} openloginform={()=>{setShowLoginForm(true)}} />
                    {/* Copy End */}
                    
                    <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                     
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                            <Typography variant="h5"   style={{color:theme.palette.secondary.main, textAlign:"center"}} gutterBottom>
                                {trendData.blog_title}  
                             </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  style={{width:"100%"}}>
                            <Typography variant="body2" style={{color:theme.palette.secondary.main,textAlign:"right"}}   >
                                <b>Date:</b> {trendData.added_date}  
                             </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" style={{width:"100%",padding:"4%"}}>
                             <img class="img-fluid " src={trendData.blog_image}   />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Share Options: </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>   
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        <Tooltip title={tooltipText} arrow>
                          <IconButton onClick={handleCopy}>
                            <LinkIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                        </Typography>
                        
                     </Grid>
                        {(trendData.is_trend==='1' && trendData.trend_type==='1') && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" style={{width:"100%",padding:"4%"}}>
                        <Button variant="contained"    style={{width:"70%"}} color="primary" component={Link} to={'/psdetails/'+trendData?.p_slug+'/'+trendData?.p_id} className="linkstyle"  >
                            View Details
                        </Button>
                        </Grid>) }
                        {(trendData.is_trend==='1' && trendData.trend_type==='2') && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" style={{width:"100%",padding:"4%"}}>
                        <Button variant="contained"    style={{width:"70%"}} color="primary" component={Link} to={'/eng-info/info-detail/'+trendData?.info_slug+'/'+trendData?.info_id} className="linkstyle"   >
                            View in EngInfo
                        </Button>
                        </Grid>) }
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{color:"black" }}  >
                            <div style={{color:"black",userSelect: "none", // CSS to disable text selection
                            MozUserSelect: "none", // For Firefox
                            WebkitUserSelect: "none", // For Safari
                            msUserSelect: "none", // For older IE
                            }}
                            onContextMenu={handleContextMenu}
                            onCopy={handleCopy}>
                                {parse(trendData.blog_desc)}
                            </div>
                        
                        </Grid>
                    </Grid></Box>)}
                    <br/><br/>
                    
              
                
           
      </div>)} </div>);

     
}