import {Dialog,Grid,Typography,TextField,DialogTitle,DialogContent,useTheme,makeStyles,Button,InputAdornment,IconButton} from '@material-ui/core';

import {useState, useEffect, useContext} from 'react';

import { UserContext,SnackbarContext,EngInfoContext } from './userContext';
import {useNavigate} from "react-router-dom";


import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import {Link} from 'react-router-dom';
import Axios from "axios";

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
     
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const NewLoginForm=(props)=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList,setEngInfoList} = useContext(EngInfoContext);


    const [num,setNum] = useState("");
    const [pass,setPass] = useState("");

    const [showPass,setShowPass] = useState(false);

    const [error,setError] = useState(0);

    const getEngInfodata=async(tkn,uid)=>{
      const formData= {};
      var tkn=tkn;
      formData.user_id=uid;
      Axios.post("/new_apis/get_bookmarks",formData,{ 
        headers: { tkn: tkn },
       }).then((res) => {
          if(res.data.status==1){
             
              var arr=[];
              for(var i=0;i<res.data.data.length;i++){
                arr.push(res.data.data[i].id);
                 
              }
              setEngInfoList(arr);
                
          }else{
  
          }
                
       });
    }


    const submitDetails=(e)=>{
        e.preventDefault();
        setError(0);
         
        if(num.length!==10 ){
             
            setError(1);  
            setSnack({
              message: "Enter Valid 10-Digit Mobile number...",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(pass.length<3){
            setError(2);  
            setSnack({
              message: "Enter your Password...",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else{
            props.closeform();
            props.showload();
            const formData= {};
            formData.num=num;
            formData.pass=pass;
            Axios.post("/new_apis/new_login",formData,{ 
                headers: { key: key },
               }).then(  (res) => {
                   
                    if(res.data.status){
                         
                        setUser(res.data.user_data);
                        props.hideload();
                        props.closeform();
                        localStorage.setItem('estkn',res.data.token);
                        getEngInfodata(res.data.token,res.data.user_data.user_id);
                        setSnack({
                          message: res.data.msg,
                          type: 'success',
                          open: true,
                          direction:"left"
                        });
                        //window.location.reload(false);

                         
                    }else{
                      props.hideload();
                      props.openloginform();
                      setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                    }
                    
                  
               }); 
        }
    }


     

     

    return (
        <Dialog
        open={props.open}
        onClose={()=>{}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      LOGIN            
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent style={{overflow:"hidden"}}>
          <form onSubmit={submitDetails}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}   >
                <TextField className={classes.root}  label="Mobile Number *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={num}
                        type="number"
                        error={error===1 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <PhoneIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        
                        onChange={(e) => {
                                 
                                    setNum(e.target.value);
                                
                                

                        }}
                        
                    />
                </Grid>
                <Grid item xs={12}    >
                <TextField className={classes.root}  label="Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={showPass ? 'text' : 'password'}
                        value={pass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <LockIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setPass(e.target.value);

                        }}
                        
                    />
                </Grid>
                 
                <Grid item xs={12}    >
                 
                </Grid>
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >LOGIN</Button>
                </Grid>
               
                 
            </Grid>
            </form>
            <hr style={{color:theme.palette.primary.main}}/>
            <div className="text-center" style={{paddingBottom:"4%"}}>
            <Button variant="contained" component={Link} to={"/signup"} style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >Signup</Button>
            </div>
            
            </DialogContent>
             
      </Dialog>
    );
}