import {useEffect, useState } from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {SingleTrend } from "./SingleTrend";
import {Grid,Box,Typography,useTheme,Container} from '@material-ui/core';
 
import {Link,Route, Routes, useNavigate, useParams} from 'react-router-dom';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Helmet  } from 'react-helmet-async';

// Copy Start

import {useContext} from 'react'
import { UserContext } from './userContext';

// Copy End

 const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


 function convertToSlug(Text)
 {
     return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
 }

export const TrendsList=()=>{
    const theme = useTheme();
     
    const [loading,setLoading]=useState(true);
    const [trends,setTrends]=useState();
    const [metadata,setMetadata] = useState();

    // Copy Start
    const {user, setUser} = useContext(UserContext);
    const contentStyle = user? {}: { filter: "blur(4px)", pointerEvents: "none" }; 
    // Copy End

   let history=useNavigate()
    useEffect(()=>{
        Axios.post("/new_apis/getsinglemetadata", {'type':"trends"},{
            headers: { key: key },
           }).then((res) => {
              
             if(res.data.status){
               
              setMetadata(res.data.metadata[0])
             } else{
                setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
                'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
                "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
             }
              
           });
        Axios.get("/new_apis/get-trends-list",{ 
          headers: { key: key },
         }).then((res) => {
            setTrends(res.data.trends);
             
             
             
            setLoading(false);
         }); 
                   
      },[]);

       

      return (<div>{metadata && <Helmet>
        <title>{metadata.seo_title}</title>
          <meta name="description" content={metadata.seo_desc} />
          <meta name="keywords" content={metadata.seo_keywords}></meta>
        </Helmet>} {loading?<Loading />:(<Container maxWidth="lg" >
        <br/><br/>
        <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
           Trends 
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Construction Trends:</span> Discover the Latest Innovations in the Industry.
      
      </Typography>
      </Container>
      <br/><br/>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
            style={contentStyle}
            >
                <Grid item xl={9} lg={8} md={8} sm={12} xs={12}>
                <Routes>
                <Route path={"trend/:slug?/:id?"}  element={<SingleTrend  />}  />
              </Routes>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={12} xs={12} >
                <Box boxShadow={3} p={3}  style={{backgroundColor:"white",border:"2px groove #ffd24d",borderRadius:"10px"}}> 
                <Typography variant="body1" className="text-center" gutterBottom style={{color:"black" }}>
                      <b>Other Trends:</b>
                </Typography>
                <ul>
                {trends?.map((item) => (
                <li><div style={{marginTop:"4%"  }}>
             
             
            <Link 
            to={"/trends-list/trend/"+convertToSlug(item?.blog_title)+"/"+item?.blog_id}
            
            className="anchorTag" style={{marginTop:"2%"  }}>&#9648;&nbsp;&nbsp;{item?.blog_title} </Link>

{/* <a href={"/trends-list/trend/"+convertToSlug(item?.blog_title)+"/"+item?.blog_id} className="anchorTag" style={{marginTop:"2%"  }}>&#9648;&nbsp;&nbsp;{item?.blog_title}</a> */}
               </div> </li>    
 
                    
))}
</ul>
<br/>
 <Link to={"/trends"}   className="anchorTag" style={{marginTop:"2%"  }}> <ArrowForwardIcon/> View All </Link>
                
                <br/><br/>
                </Box>
                </Grid>
            </Grid>
      </Container>)} </div>);

     
}